.calendar {
	position: absolute;
	z-index: 9999;
	width: 300px;
	filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));

	.day {
		display: flex;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		justify-content: center;
		align-content: center;
		align-self: center;
		justify-self: center;
		line-height: 21px;
	}
}